<template>
	<div class="ysm-settings">
		
		<div class="order">
			<div class="con" style="width: 80%;display: flex;justify-content: space-between;">
			<div class="conditions" align="left">
				<h2>个人信息<span style="text-align: right;margin-left: 250px;cursor: pointer;"  @click="tomodifyPwd" >修改密码</span></h2>
				<p>基础信息</p>
				<div align="center">
					<el-form   :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
					  <el-form-item label="医师姓名:" prop="name" style="text-align: left;">
						  {{ruleForm.dname}}
					  </el-form-item>
					  <el-form-item label="医师证书省份:" prop="cardNo" style="text-align: left;">
						  {{ruleForm.cardNo}}
					  </el-form-item>
					  <el-form-item label="医师证书职业地点:" prop="credentialsAddr" style="text-align: left;">
					  		 {{ruleForm.credentialsAddr}}
					  </el-form-item>
					   <el-form-item label="手机号码" prop="phone">
					     <el-input v-model="ruleForm.phone"></el-input>
					   </el-form-item>
					   <!-- 验证码 -->
					   <el-form-item label="验证码" prop="userCode">
					   	<el-input placeholder="请输入短信验证码" v-model="ruleForm.userCode">
					   		<!-- <template slot="prefix"><img src="../assets/register/code.png" width="20"
					   				height="20" style="margin-top: 0.3125rem;" /></template> -->
					   		<template slot="suffix"><button :disabled="zaicifasong" type="button" id="c"
					   				@click="getCode">{{phoneCode}}</button></template>
					   	</el-input>
					   </el-form-item>
					  <el-form-item label="网站昵称" prop="webName">
					    <el-input v-model="ruleForm.webName"></el-input>
					  </el-form-item>
					  <!-- <el-form-item label="邮箱地址" prop="name">
					    <el-input v-model="ruleForm.name"></el-input>
					  </el-form-item> -->
					  <el-form-item label="工作单位" prop="company">
					    <el-input v-model="ruleForm.company"></el-input>
					  </el-form-item>
					<el-form-item label="所在城市" prop="stateName">
					  <el-input v-model="ruleForm.stateName"></el-input>
					</el-form-item>
					    <el-button  class="nextStep" style="width: 200px;" type="primary" @click="submitForm('ruleForm')">修改信息</el-button>
					</el-form>
				</div>
			</div>
			
			<div class="right">
				<h2 align="left">收货地址信息</h2>
				<div align="left" style="overflow-y: auto; min-height: 145px; ">
					<div class="ress" align="left" v-for="address in doctorAddresses">
						<p>{{address.userName}},{{address.phone}}</p>
						<p>{{address.cityInfo}}</p>
						<p style="border-bottom: 1px solid lightgray;width: 100%;margin-bottom: 2px;"></p>
						<el-radio-group v-model="radio" @change="setDefaultChange">
							<el-radio v-if="address.isdefault==1" :label="address.id">已设为默认</el-radio>
							<el-radio v-if="address.isdefault==0" :label="address.id">设为默认</el-radio>
						</el-radio-group>
						<span style="cursor: pointer;padding-left: 25px;font-size: 14px;" @click="delAddress(address.id)">删除</span>
						<!-- <el-button size="mini" style="font-size: 12px;margin-left: 20px;height: 25px!important;line-height: 20px !important;border: none;color: #1196AB;" @click="delAddress(address.id)">删除</el-button> -->
					</div>
					<el-button class="nextStep" type="primary" @click="toNewAddress"
							>新增收货地址</el-button>
				    <el-button class="btj" v-if="isShow" @click="backTiJiao">返回订单</el-button>
				</div>
				<div align="left">
					<!-- <h2 @click="modifyPwd">修改密码</h2> -->
				
				</div>
				 
				
			</div>
			</div>
		</div>
		
		<div class="f">Copyright&copy;www.techlion.com.cn All Rights Reserved<br/>山东泰克莱恩 0513-88762550</div>
		<el-dialog @close="resetpwdForm('pwdForm')" title="修改密码" :visible.sync="dialogFormVisible" width="30%" top="30vh">
		  <el-form :model="pwdForm" ref="pwdForm" :rules="pwdFormRules">
		    <el-form-item  label="旧密码" :label-width="formLabelWidth" prop="oldPassword">
		      <el-input type="password" v-model="pwdForm.oldPassword" autocomplete="off"></el-input>
		    </el-form-item>
		  <el-form-item label="新密码" :label-width="formLabelWidth" prop="password">
		    <el-input type="password" v-model="pwdForm.password" autocomplete="off"></el-input>
		  </el-form-item>
		  <el-form-item label="确认密码" :label-width="formLabelWidth" prop="rePassword">
		    <el-input type="password" v-model="pwdForm.rePassword" autocomplete="off"></el-input>
		  </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="dialogFormVisible = false">取 消</el-button>
		    <el-button type="primary" @click="modifyPwd('pwdForm')">确 定</el-button>
		  </div>
		</el-dialog>
		<!-- 添加地址的dialog -->
		<el-dialog  style="z-index: 10;" title="收货地址" :visible.sync="dialogAddressFormVisible" width="40%" @close ='resetDialogForms'>
			<el-form :model="addressruleForm" label-width="80px" ref="addressruleForm" :rules="addressrules">
				<el-form-item label="收货人" :label-width="formLabelWidth" prop="userName">
					<el-input v-model="addressruleForm.userName" autocomplete="off" placeholder="请输入收货人姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机号" :label-width="formLabelWidth" prop="phone">
					<el-input v-model="addressruleForm.phone" autocomplete="off" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="地区" :label-width="formLabelWidth" prop="regionId" required>
					<el-cascader style="width: 30%;position: relative;left: -25px;" v-model="value" :options="sheng" :props="{ expandTrigger: 'hover' }"
						@change="handleChange"></el-cascader>
					<el-cascader style="width: 30%;position: relative;left: -12.5px;" v-model="shiValue" :options="shi" :props="{ expandTrigger: 'hover' }"
						@change="handleChange1"></el-cascader>
					<el-cascader style="width: 30%;" v-model="quValue" :options="qu" :props="{ expandTrigger: 'hover' }"
						@change="handleChange2"></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" :label-width="formLabelWidth" prop="address">
					<el-input v-model="addressruleForm.address" autocomplete="off" placeholder="请输入详细地址"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="submitAddressForm('addressruleForm')">添加</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui';
	export default {
		data: function() {
			//验证验证码
			var checkValidateCode = (rule, value, callback) => {
				console.log(value);
				console.log(this.yzCode);
				if (value != this.yzCode) {
					callback(new Error('验证码输入有误'));
				} else {
					callback();
				}
			}
			return {
				zaicifasong:false,
				si:'',
				phoneCode: "获取验证码",
				isShow:false,
				value:'',
				dialogAddressFormVisible:false,
				 formLabelWidth: '120px',
				dialogFormVisible:false,
				radio:'',
				doctorAddresses:[],
				qu: [],
				quValue: [],
				shi: [],
				shiValue: [],
				sheng: [],
				options: [], //存储选中的省市区
				addressruleForm: {
					credentialsAddr:'',
					doctorId: '',
					regionId: "",
					address: '',
					phone: '',
					userName: '',
					oid: '0'
				},
				addressrules: {
					userName: [{
						required: true,
						message: '请输入收货人姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}],
					regionId: [{
						required: true,
						message: '请输入省市区',
						trigger: 'change'
					}],
				
				},
				ruleForm: {
						  userCode:'',
				          dname: '',
						  id:'',
				          status: '',
				          regionId: '',
				          phone: '',
				          password: '',
				          company:'',
				          stateName: '',
				          webName: '',
						  credentialsAddr:'',
						  cardNo:''
				        },
				        rules: {
							userCode: [
							  { required: true, message: '请输入验证码', trigger: 'blur' },
							  {
							  	min: 6,
							  	max: 6,
							  	message: "验证码为六位数字"
							  },
							  {
							  	validator: checkValidateCode,
							  	trigger: 'blur'
							  }
							],
				          webName: [
				            { required: true, message: '请输入网站昵称', trigger: 'change' }
				          ],
				          company: [
				            {  required: true, message: '请输入公司名称', trigger: 'change'  }
				          ],
						  stateName: [
						    {  required: true, message: '请输入所在城市', trigger: 'change'  }
						  ],
						 phone: [{
						 		required: true,
						 		message: '请输入手机号',
						 		trigger: 'blur'
						 	},
						 	{
						 		min: 11,
						 		max: 11,
						 		message: '长度为11个字符',
						 		trigger: 'blur'
						 	}/* ,
						 	{
						 		pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
						 		message: '手机号格式有误'
						 	} */
						 ]
				         
				         
				        },
				pwdForm:{
					id:'',
					password:'',
					oldPassword:'',
					rePassword:''
				},
				pwdFormRules:{
					oldPassword: [
					  { required: true, message: '请输入原密码', trigger: 'change' }
					],
					password: [
					  { required: true, message: '请输入新密码', trigger: 'change' }
					],
					rePassword: [
					  { required: true, message: '请输入确认密码', trigger: 'change' }
					],
				}
			}
		},
		methods: {
			//获取验证码
			getCode() {
				var _this=this;
				this.zaicifasong=true;
				let num = 60;
				//this.phoneCode=num+"秒";
				this.si = setInterval(() => {
					let n = num--;
					if (n < 1) {
						//document.getElementById("c").removeAttribute("disabled");
						clearInterval(_this.si);
						this.phoneCode = "获取验证码";
						this.yzCode = '';
						this.zaicifasong=false;
					} else {
						this.phoneCode = n + "秒";
					}
				}, 1000)
				this.$axios.get("getLoginCode", {
					params: {
						phone: this.ruleForm.phone
					}
				}).then(res => {
					console.log(res.data.data.resCode);
					this.yzCode = res.data.data.resCode;
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//返回订单
			backTiJiao(){
				
				this.$router.push("/ysmmain/ysmorder/ysmorderten")
			},
			//新增收货地址
			//添加新的收货地址
			toNewAddress() {
				this.dialogAddressFormVisible = true;
				this.getShengShiQu();
			},
			//添加新地址
			submitAddressForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post("/client/doctor/addAddress", JSON.stringify(this.addressruleForm), {
							headers: {
								"Content-type": "application/json"
							}
						}).then(res => {
							console.log(res);
							if (res.data.code == 200) {
								this.dialogAddressFormVisible = false;
								this.$alert("添加地址成功","提示");
								this.initForm();
								this.isShow=true;
								this.getAddressById();
							}
						}).catch(err => {
							this.$message.error("请联系管理员");
						});
					} else {
						this.$alert("地址信息填写不完整","提示");
						return false;
					}
				});
			},
			//dialog关闭的时候清空表单内容
			initForm() {
				this.addressruleForm.address = '';
				this.addressruleForm.phone = '';
				this.addressruleForm.userName = '';
				this.value = [];
				this.shiValue = [];
				this.quValue = [];
			},
			//查询省市区
			getShengShiQu() {
				this.$axios.get("/queryNextLevelRegions", {
					params: {
						pid: 100000
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.sheng.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//根据省查询市
			handleChange(v) {
				console.log(v);
				console.log(this.value);
				//每次添加市之前先清空数组
				this.shi = [];
				//根据省id查询市
				this.$axios.get("queryNextLevelRegions", {
					params: {
						pid: v[0]
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.shi.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//根据市查询区
			handleChange1(v) {
				console.log(v);
				console.log(this.value);
				//每次添加区之前先清空数组
				this.qu = [];
				//根据省id查询市
				this.$axios.get("queryNextLevelRegions", {
					params: {
						pid: v[0]
					}
				}).then(res => {
					let a = res.data.data
					for (let i = 0; i < a.length; i++) {
						let obj = {
							"value": a[i].id,
							"label": a[i].name
						};
						this.qu.push(obj);
					}
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			},
			//单击dialog的取消清空form
			cancel() {
				this.initForm();
				this.dialogAddressFormVisible = false;
			},
			//选中区之后给ruleForm中的cityInfo赋值
			handleChange2(v) {
				console.log(v[0]);
				this.addressruleForm.regionId = v[0];
			},
			resetDialogForms(){
				
				this.$refs.addressruleForm.resetFields();
			},
			 submitForm(formName) {
			        this.$refs[formName].validate((valid) => {
			          if (valid) {
						  var _this=this;
						  this.$axios.post("client/doctor/updDoctorInfoSelf",JSON.stringify(this.ruleForm),{
						  	headers:{
						  		"Content-type":"application/json"
						  	}
						  }).then(res=>{
							  console.log(res.data);
							  
							   this.$alert('修改个人基础信息成功', '提示', {
							            confirmButtonText: '确定',
							            callback: action => {
							            clearInterval(_this.si);
							            _this.phoneCode = "获取验证码";
							            _this.yzCode = '';
										_this.ruleForm.userCode='';
										_this.$router.push("/ysmmain/ysmsettings");
							            }
							          });
							 /*   clearInterval(this.si);
								this.phoneCode = "获取验证码";
								this.yzCode = '';
							    this.$alert('修改个人基础信息成功',"提示"); */
						  	
						  }).catch(err=>{
						  	this.$alert("请联系管理员", "提示");
						  })
			          } else {
			           this.$alert('个人信息不完成',"提示");
			            return false;
			          }
			        });
			      },
			      resetForm(formName) {
			        this.$refs[formName].resetFields();
			      },
				  //设置默认地址
				  isDefaultAddress(addrId){
					  //根据id查询医师个人中信息
					  this.$axios.get("/client/doctor/setDefaultAddress",{
					  	params:{
					  		doctorId:this.ruleForm.id,
							addrId:addrId
					  	}
					  }).then(res=>{
					  	
					  }).catch(err=>{
					  	console.log(err);
					  });
				  },
				  //修改默认地址
				  setDefaultChange(addressId) {
					
				  	this.radio = addressId;
					console.log(this.ruleForm.id);
					console.log(addressId);
				  	this.$axios.get("/client/doctor/setDefaultAddress", {
				  		params: {
				  			doctorId: this.ruleForm.id,
				  			addrId: addressId
				  		}
				  	}).then(res => {
				  		console.log(res);
						if(res.data.code==200){
							//查询所有地址信息
							this.$axios.get("/client/doctor/queryDoctorAddress",{
								params:{
									doctorId:this.ruleForm.id
								}
							}).then(res=>{
								console.log(res.data);
								this.doctorAddresses = res.data.data.addressList;
								this.defaultAddress = res.data.data.defaultAddress;
								this.radio = this.defaultAddress.id;
							}).catch(err=>{
								console.log(err);
							});
						}
				  	}).catch(err => {
				  		this.$message.error("请联系管理员");
				  	});
				  },
				  //删除地址
				  delAddress(addrId) {
					 /* var result=window.confirm('此操作将永久删除该文件, 是否继续?');
					  if(result){
						  this.$axios.get("/client/doctor/delAddress",{
						  	params:{
						  		addrId:addrId
						  	}
						  }).then(res=>{
						  	  if(res.data.code==200){
						  										 this.$alert('地址删除成功', '提示', {
						  										           confirmButtonText: '确定',
						  										           callback: action => {
						  										             this.getAddressById();
						  										           }
						  										         });
						  									  }
						  	
						  }).catch(err=>{
						  	  this.$alert("删除地址失败,请联系管理员","提示");
						  });
					  } */
					 this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					            confirmButtonText: '确定',
					            cancelButtonText: '取消',
					            type: 'warning'
					          }).then(() => {
								  //根据地址id删除地址
								  this.$axios.get("/client/doctor/delAddress",{
								  	params:{
								  		addrId:addrId
								  	}
								  }).then(res=>{
								  	  if(res.data.code==200){
										 this.$alert('地址删除成功', '提示', {
										           confirmButtonText: '确定',
										           callback: action => {
										             this.getAddressById();
										           }
										         });
									  }
								  	
								  }).catch(err=>{
								  	  this.$alert("删除地址失败,请联系管理员","提示");
								  });
					            
					          }).catch(() => {
					            this.$message({
					              type: 'info',
					              message: '已取消删除'
					            });          
					          });
					 
				  	
				  },
				  tomodifyPwd(){
					   this.dialogFormVisible=true; 
				  },
				  //修改密码
				  modifyPwd(formName){
					
					  this.$refs[formName].validate((valid) => {
					    if (valid) {
							//1.获取当前登录用户的密码
							let pwd=JSON.parse(sessionStorage.getItem("loginInfo")).password;
							if(pwd!=this.pwdForm.oldPassword){
								this.$alert("原密码输入有误","提示");
							}else if(this.pwdForm.password!=this.pwdForm.rePassword){
								this.$alert("两次密码不一致","提示");
							}else{
								this.$axios.post("/client/doctor/updPwd",JSON.stringify(this.ruleForm),{
									headers:{
										"Content-type":"application/json"
									}
								}).then(res=>{
									  let _this=this;
									  setTimeout(() => {_this.dialogFormVisible=false;_this.$router.push("/login");},100)
										// window.setTimeout(function(){
										// 	_this.dialogFormVisible=false;
										// 	_this.$router.push("/login");
										// },500)
									
								}).catch(err=>{
									this.$alert("请联系管理员", "提示");
								})
							}
					  	 
						  
					    } else {
					     // this.$alert('个人信息不完成',"提示");
					      return false;
					    }
					  });
				  },
				  //关闭dialog之后,将表单数据情况
				  resetpwdForm(formName){
					    this.$refs[formName].resetFields();
					    this.pwdForm.oldPassword='';
						this.pwdForm.password='';
						this.pwdForm.rePassword='';
				  },
				  //根据医生id查询它的地址信息
				  getAddressById(){
					  //根据id查询医师地址信息
					  this.$axios.get("/client/doctor/queryDoctorAddress",{
					  	params:{
					  		doctorId:this.ruleForm.id
					  	}
					  }).then(res=>{
					  	
					  	this.doctorAddresses=res.data.data.addressList;
					  	
					  }).catch(err=>{
					  	console.log(err);
					  });
				  }
		
		},
		created: function() {
			document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue visible")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2")
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")
			let id = JSON.parse(sessionStorage.getItem("loginInfo")).id;
			console.log(JSON.parse(sessionStorage.getItem("loginInfo")).dname);
			this.ruleForm.dname=JSON.parse(sessionStorage.getItem("loginInfo")).dname;
			console.log(this.ruleForm.dname);
			
			if(id!="undefined"&&id!=''&&id!=null&&id!=undefined){
				this.ruleForm.id=id;
				this.addressruleForm.doctorId=id;
				//根据id查询医师个人中信息
				this.$axios.get("/client/doctor/initSettings",{
					params:{
						doctorId:this.ruleForm.id
					}
				}).then(res=>{
					let doctorInfo=res.data.data.doctorInfo;
					console.log(doctorInfo);
					this.ruleForm=doctorInfo;
					this.doctorAddresses=res.data.data.doctorAddresses;
					console.log(this.ruleForm);
					console.log(res.data.data.doctorAddresses);
					for (var i = 0; i < this.doctorAddresses.length; i++) {
						if (this.doctorAddresses[i].isdefault == 1) {
							this.radio = this.doctorAddresses[i].id;
						}
					}
				}).catch(err=>{
					console.log(err);
				});
			}
			
			
			//将当前路径存储到backLocation
			this.$store.commit("setBackLocation", "/hxmain/hxsettings");
		}
	}
</script>

<style scoped="scoped">
	.ysm-settings {
		width: 100%;
		height: 100%;
		background-color: transparent;
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		margin-bottom: 1.875rem;
		/* z-index: 0; */
	}
    h2{
		color: #af9f73;
	}
	.conditions p{
		padding-left: 25px;
	}
	.nextStep{
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
	}
	.order {
		position: relative;
		background-color: white;
		min-width: 1550px;
		min-height: 31.75rem;
		margin-top: 16.75rem;
		margin-bottom: 15.75rem;
		padding-top: 1.25rem;
		padding-bottom: 10px;
	    display: flex;
	    justify-content: center;
	}

	

	/* 条件 */
	.conditions {
		box-sizing: border-box;
		background-color: #f0f0ee;
		border-radius: 15px;
		padding: 10px 20px 20px 20px;
		width: 500px;
		margin: 0px auto;
		/* border: 1px solid red; */
		position: relative;
		left: -100px;
	}
	.right{
		width: 500px;
		/* border: 1px solid blue; */
		min-height: 31.75rem;
		position: relative;
		
	}
.ress{
	/* border: 1px solid red; */
	/* background-color: lightgray; */
	/* border-radius: 15px; */
	padding-left: 10px;
	height: 120px;
	
}
.ress p{
	
}
	.primary {
		margin: 0px 0px 1px;
		padding: 6px 12px 6px 12px;
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		border-radius: 0px;
		width: 5.125rem;
		height: 2.3rem;
	}
	.f {
		position: relative;
		background-color: #444;
		padding: 10px 0 10px 0;
		color: #fff;
		bottom: 0rem;
	}
	.btj{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}
	#c {
		width: 6.25rem;
		height: 39.15px;
		border: none;
		background: #af9f73;
		position: relative;
		right: -0.3125rem;
		color: white;
	}
</style>
